import { chevronBackOutline, funnel } from "ionicons/icons";
import React, {useEffect} from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch } from "react-redux";

import { IonIcon, IonRadio, IonRadioGroup } from "@ionic/react";

import CheckBox from "../../components/CheckBox/Index";
import {
  setSortValue,
  sortOptions,
  useAuctionItemsState,
  useSortValue,
} from "../../redux/slices/auctionItemsSlice";
import Text from "../CommonStyles/Text";
import { SlideOutState } from "./Index";
import {
  AttributeLI,
  AttributeLabel,
  BackButton,
  FilterBox,
  SlideOutTitle,
  SortButton,
  Span,
  UL,
} from "./styledComponents";

/**
 * Filters & Tags
 */
export let filtersConfig = [];

type Props = {
  slideoutState: SlideOutState;
  setSlideoutState: Function;
  handleCheckboxChange: Function;
  filtersState: any;
  setItemHasLiveOnly: boolean;
};
export const SlideOutMenu = ({
  slideoutState,
  setSlideoutState,
  handleCheckboxChange,
  filtersState, setItemHasLiveOnly
}: Props) => {
  const dispatch = useDispatch();
  const { tags: auctionItemsTags } = useAuctionItemsState();
  const sortValue = useSortValue();

  // useEffect(() => {
  //   console.log(slideoutState)
  // }, [slideoutState])

  useEffect(() => {
    filtersConfig = [
      { param: "watchlist", name: "Watchlist", filteredValue: true },
      { param: "accountbiddingon", name: "My Bids", filteredValue: true }
    ]
    if (setItemHasLiveOnly) {
      filtersConfig.push({ param: "itemisliveonly", name: "Live Auction Only", filteredValue: true })
    }
    filtersConfig.push({ param: "onlineauction", name: "Online Auction", filteredValue: true })
  }, [])

  const handleClickAway = () => {
    // console.log('handleClickAway')
    // console.log(slideoutState)
    if (slideoutState !== SlideOutState.closed) {
      setSlideoutState(SlideOutState.closed);
    }
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FilterBox isOpen={slideoutState !== SlideOutState.closed}>
        {slideoutState === SlideOutState.filters ? (
          <>
            <SlideOutTitle>
              <BackButton
                onClick={() => setSlideoutState(SlideOutState.closed)}
              >
                Done
              </BackButton>
              <Text
                fontSize="16px"
                fontWeight="600"
                style={{ textAlign: "start" }}
              >
                Filter
              </Text>
            </SlideOutTitle>
            <Text fontSize="14px" fontWeight="800" padding="7px 0 0 7px">
              Sort
            </Text>
            <SortButton onClick={() => setSlideoutState(SlideOutState.sort)}>
              {sortValue}
              <IonIcon icon={funnel} style={{ fontSize: "16px" }}></IonIcon>
            </SortButton>
            <Text fontSize="14px" fontWeight="800" padding="7px 0 0 7px">
              Show Only
            </Text>
            <UL>
              {filtersConfig.map(
                (filter: { name: string; param: string, filteredValue: boolean }, index: number) => {
                  return (
                    <AttributeLI key={index}>
                      <AttributeLabel>
                        <CheckBox
                          name={filter.name}
                          param={filter.param}
                          handleCheckboxChange={handleCheckboxChange}
                          checked={filtersState.filters.includes(filter.param)}
                          data-filter-type="filters"
                          checkedBackgroundColor="#5732db"
                          uncheckedBackgroundColor="#a2a2a2"
                        ></CheckBox>
                        <span>
                          <Span>{filter.name}</Span>
                        </span>
                      </AttributeLabel>
                    </AttributeLI>
                  );
                }
              )}
            </UL>
            <Text fontSize="14px" fontWeight="800" padding="7px 0 0 7px">
              Tags
            </Text>
            <UL>
              {auctionItemsTags.map((tag: string, index: number) => {
                return (
                  <AttributeLI key={index}>
                    <AttributeLabel>
                      <CheckBox
                        name={tag}
                        param={tag}
                        handleCheckboxChange={handleCheckboxChange}
                        key={index}
                        checked={filtersState.tags.includes(tag)}
                        data-filter-type="tags"
                        checkedBackgroundColor="#5732db"
                        uncheckedBackgroundColor="#a2a2a2"
                      ></CheckBox>
                      <span>
                        <Span>{tag}</Span>
                      </span>
                    </AttributeLabel>
                  </AttributeLI>
                );
              })}
            </UL>{" "}
          </>
        ) : (
          <>
            <SlideOutTitle>
              <BackButton
                onClick={() => setSlideoutState(SlideOutState.filters)}
              >
                <IonIcon icon={chevronBackOutline} />
                Back
              </BackButton>
              <Text
                fontSize="16px"
                fontWeight="600"
                style={{ textAlign: "start" }}
              >
                Sort
              </Text>
            </SlideOutTitle>

            <UL>
              <IonRadioGroup value={sortValue}>
                {Object.values(sortOptions).map(
                  (sort: string, index: number) => {
                    return (
                      <AttributeLI
                        key={index}
                        onClick={() => dispatch(setSortValue(sort))}
                      >
                        <AttributeLabel>
                          <IonRadio slot="start" value={sort} />
                          <span>
                            <Span>{sort}</Span>
                          </span>
                        </AttributeLabel>
                      </AttributeLI>
                    );
                  }
                )}
              </IonRadioGroup>
            </UL>
          </>
        )}
      </FilterBox>
    </ClickAwayListener>
  );
};
