import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {sendUpdateCard, userpaymentmethod, deleteCard} from "../../util/api/userpaymentmethod";
import {
  IonBackdrop,
  IonButton,
  IonCardTitle,
  IonCol, IonContent, IonFooter,
  IonGrid, IonHeader, IonItem, IonLabel, IonLoading,
  IonModal, IonPage,
  IonRow,
  IonText, IonTitle,
  IonToast,
  IonToggle, IonToolbar, useIonModal
} from "@ionic/react";

import './main.css';

import {
  checkTokenOnRefresh, updateStateOnLogout,
  useUserInfo,
} from "../../redux/slices/userSlice";
import { USER_PROFILE, getEndPoint } from "../../util/api/enpoints";
import ButtonItem from "../CommonStyles/ButtonItem";
import Text from "../CommonStyles/Text";
import {
  PageWrapper,
} from "./StyledComponents";
import styled from "@emotion/styled"
import waveBluePng from "../../assets/img/svg/wave-blue.png";
import {loadStripe} from "@stripe/stripe-js";
import store from "../../redux/store";
import {setForceLogout} from "../../redux/slices/eventSlice";


const EventNameContainer = styled.div`
  background-color: #b6d9ee;
  width: 100%;
  min-height: 45px;
`
const WaveContainer = styled.div`
  background: url(${waveBluePng}) no-repeat;
  width: 100%;
  min-height: 45px;
  background-size: 100% 100%;
`

const ModalContent = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  height: 32em;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

interface Params {
  eventcode: string
  id?: string;
  login?: string;
  itemid?: string
}
const AccountUpdatePaymentMethod: React.FC = () => {
  const { eventcode, id, login, itemid } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const user = useUserInfo()
  const eventState = useSelector((state: any) => state.event)
  const [replaceCardTrigger, setReplaceCardTrigger] = useState<boolean>(false)
  const [showStripeForm, setShowStripeForm] = useState<boolean>(false)
  const [toastObj, setToast] = useState({ display: false, message: '', goBack: false });
  const [stripeObj, setStripeObj] = useState(null)
  const [stripeElement, setStripeElement] = useState(null)
  const [reloadData, setReloadData] = useState(0)
  const [orderTotal, setOrderTotal] = useState(100)
  const [stripePaymentElement, setStripePaymentElement] = useState(null)
  const [processorToken, setProcessorToken] = useState('');
  const [userPaymentMethodData, setUserPaymentMethodData] = React.useState({
    cardmessage: '',
    message: '',
    paymentprocessorcode: 0,
    paymentprocessorisvalid: true,
    paymentprocessorinfo: {
      stripeaccountid: '',
      stripeapikey: '',
    },
    showremovedbutton: false,
    showreplacedbutton: false,
    showstripeiframe: false,
    status: '',
    type: '',
    userpaymentmethodtitle: ''
  })


  const [modalState, setModalState] = useState({
    display: false,
    title: '',
    message: '',
    goBack: false
  })

  const [toastState, setToastState] = useState({
    display: false,
    goback: false,
    message: ''
  })

  const [isLoading, setIsLoading] = useState(
      false
  )

  useEffect(() => {
    if (replaceCardTrigger) {
      setShowStripeForm(true)
    }
    else {
      setShowStripeForm((userPaymentMethodData?.paymentprocessorcode == 2 && userPaymentMethodData?.showstripeiframe))
    }
  }, [userPaymentMethodData?.paymentprocessorcode, userPaymentMethodData?.showstripeiframe, replaceCardTrigger])

  useEffect(() => {
    if (userPaymentMethodData?.paymentprocessorinfo?.stripeapikey && userPaymentMethodData?.paymentprocessorinfo?.stripeapikey.length > 0) {
      loadStripeAccount()
    }
  }, [userPaymentMethodData?.paymentprocessorinfo?.stripeapikey, userPaymentMethodData?.paymentprocessorinfo?.stripeaccountid])

  const loadStripeAccount = async () => {
    const stripeTempObj = await loadStripe(userPaymentMethodData?.paymentprocessorinfo?.stripeapikey, {
      stripeAccount: userPaymentMethodData?.paymentprocessorinfo?.stripeaccountid,
    });
    setStripeObj(stripeTempObj);
  }

  const [errorModal, setErrorModal] = useState({
    display: false,
    message: '',
  })

  const options = {
    mode: 'setup',
    // amount: orderTotal * 100,
    paymentMethodCreation: 'manual',
    currency: 'usd',
    paymentMethodTypes: ['card'],
    //confirm = true,
    setupFutureUsage: 'off_session',
  };

  useEffect(() => {
    if (stripeObj) {
      const elements = stripeObj.elements(options);
      setStripeElement(elements)
      const paymentElement = elements.create('payment', {
        layout: {
          type: 'tabs',
          defaultCollapsed: false,
        },
      });
      paymentElement.mount('#payment-element');

      paymentElement.on('change', async function(event) {
        if (event.complete) {
          await elements.submit();
          const {error, confirmationToken} = await stripeObj.createConfirmationToken({elements: elements})
          if (confirmationToken) {
            setProcessorToken(confirmationToken.id)
          }
          else if (error) {
            const errorMessage = error?.message
            setErrorModal({
              display: true,
              message: errorMessage,
            })
          }
          console.log(confirmationToken)
          console.log(error)
        }
      });
      setStripePaymentElement(paymentElement)
    }
  }, [stripeObj])

  useEffect(() => {
    const getUserData = async () => {
      await dispatch(
          checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
      )
    }
    const getpaymentmethoddata = async () => {
      const response = await userpaymentmethod()
      const parsedData = JSON.parse(response.jsonString)
      console.log(parsedData)
      setUserPaymentMethodData(parsedData)
    }

    getUserData()
    getpaymentmethoddata()
  }, [dispatch, reloadData])

  const goBack = () => {
    // if (toastObj.goBack) {
      const mypath = '/' + eventcode + '/my-account'
      navigate(mypath)
    // }
  }

  const clickRemoveCard = async () => {
    setIsLoading(true)
    const response = await deleteCard()
    console.log(response)
    setReloadData(reloadData+1)
    setIsLoading(false)
  }

  const clickBackToLogin = async () => {
    store.dispatch(setForceLogout(false))
    dispatch(updateStateOnLogout())
    localStorage.removeItem("token")
    dispatch({
      type: "DISCONNECT_SIGNALR"
    })
    navigate('/' + eventcode + '/login')
  }

  const clickUpdate = async () => {
    setIsLoading(true)
    if (!processorToken || processorToken.length <= 0) {
      setIsLoading(false)
      return setToastState({ display: true, goback: false, message: `Please enter a valid credit card.`})
    }
    else {
      const checkoutObj = {
        processortoken: processorToken
      }
      const response = await sendUpdateCard(checkoutObj)
      const parsedData = JSON.parse(response.jsonString)
      let title = 'An error has occurred!'
      let message = parsedData.message
      setIsLoading(false)
      if (parsedData.status === 'success') {
        if (stripePaymentElement) {
          stripePaymentElement.clear()
        }
        title = ''
      }
      if (message.length > 0) {
        return setToastState({ display: true, goback: true, message: message})
      }
      else {
        if (login) {
          navigate('/' + eventcode + '/welcome/')
        }
        else if (itemid) {
          navigate("/" + eventcode + "/auction-items/" + itemid + '/popupbuyitnow/popup' );
        }
        else if (id == undefined) {
          goBack()
        }
        else {
          navigate("/" + eventcode + "/place-bid/" + id );
        }
      }
      //
      // setModalState({
      //   display: true,
      //   title: title,
      //   message: message,
      //   goBack: true
      // })
      //
      // setTimeout(() => {
      //   setModalState({
      //     display: false,
      //     title: '',
      //     message: '',
      //     goBack: false
      //   })
      //
      //   if (parsedData.status === 'success') {
      //     return parsedData({ display: true, goback: true, message: message})
      //   }
      // }, 3000)
    }
  }


  return (
    <PageWrapper>
      {/*{id == undefined &&*/}
          <>

      <EventNameContainer>
        <Text
            fontSize="35px"
            fontWeight="bold"
            textAlign="center"
            padding="24px 0 16px 0"
        >
          {id == undefined &&
              "Update Payment Method"
          }
          {id &&
              "Add Card to Bid"
          }
        </Text>
      </EventNameContainer>

        <WaveContainer></WaveContainer>
          </>
      {/*}*/}

      <IonLoading isOpen={isLoading}/>
      <IonModal isOpen={modalState.display} className="custom-message">
        <IonBackdrop />
        <ModalContent>
          <h1>{modalState.title}</h1>
          <p>{modalState.message}</p>
        </ModalContent>
      </IonModal>
      <IonToast
          isOpen={toastState.display}
          onDidDismiss={() => {
            if (toastState.goback) {
              navigate(-1)
            }
            setToastState({
              display: false,
              goback: false,
              message: ''
            })
          }}
          cssClass={'custom-toast'}
          message={toastState.message}
          duration={3000}
      />

      <IonModal isOpen={errorModal.display} className="custom-message">
        <IonBackdrop />
        <ModalContent>
          <h1>{errorModal.message}</h1>
          <h6>Troubleshooting:</h6>
          <p>
            <ul>
              <li key={1}>Check that popups are not blocked.</li>
              <li key={2}>Make sure the Google Chrome setting allow sites to check if you have payment methods saved is turned on.</li>
              <li key={3}>Make sure you have a card saved in your Google Wallet.</li>
              <li key={4}>Make sure the Google Chrome setting allow sites to check if you have payment methods saved is turned on.</li>
            </ul>
          </p>
        </ModalContent>
      </IonModal>
      <IonToast
        isOpen={toastObj.display}
        onDidDismiss={() => goBack()}
        message={toastObj.message}
        cssClass={'custom-toast'}
        duration={3000}
      />
      <IonGrid>
        <IonRow>
          <IonCol className="ion-align-items-start" size="12">
            <IonItem lines="none">
              <div dangerouslySetInnerHTML={{ __html: userPaymentMethodData?.userpaymentmethodtitle }} />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow hidden={(id != undefined || login != undefined)}>
          <IonCol>
            <IonItem lines="none">
              <IonLabel>
                <h3>Current Payment Method On File:</h3>
                <div dangerouslySetInnerHTML={{ __html: userPaymentMethodData?.cardmessage }} />
                <IonGrid style={{width: '100%'}}>
                  <IonRow>
                    {userPaymentMethodData?.showremovedbutton &&
                      <IonCol size="6">
                        <IonButton class={'ion-button-update-payment'} size="small" fill="outline" shape="round" onClick={() => {clickRemoveCard()}}>Remove Card</IonButton>
                      </IonCol>
                    }
                    {userPaymentMethodData?.showreplacedbutton &&
                        <IonCol size="6">
                          <IonButton class={'ion-button-update-payment'} size="small" fill="outline" shape="round" onClick={() => {setReplaceCardTrigger(true)}}>Replace Card</IonButton>
                        </IonCol>
                    }
                  </IonRow>
                </IonGrid>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <div>
              <div hidden={!showStripeForm} id="payment-element"></div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonRow hidden={!showStripeForm} className="ion-align-items-center" style={{ marginTop: 16 }}>
        <IonCol hidden={login == undefined}>
          <ButtonItem backgroundColor={'white'} color={'black'} height="45px" onClick={() => { clickBackToLogin() }}>
            Back To Login
          </ButtonItem>
        </IonCol>
        <IonCol>
          <ButtonItem opacity={processorToken?.length > 0 ? 1 : .4} disabled={processorToken?.length > 0 ? false : true} height="45px" onClick={() => { clickUpdate() }}>
            Save Card
          </ButtonItem>
        </IonCol>
      </IonRow>
    </PageWrapper>
  );

};

export default AccountUpdatePaymentMethod;
