import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { CHECKOUT_CART, HOST } from "../../util/api/enpoints"
import { client } from "../../util/api/fetchData"

export type paymentprocessorinfo = {
  stripeaccountid: string,
  creditcardiframe: string
  javascriptfile:  string
  transcenterid: string
  processorid: string
  transactiontype: string
}

export type ItemWonList = {
  itemid: number
  historyid: number
  name: string
  description: string
  itemnum: string
  quantity: number
  amount: number
  location: string
  pickinstructions: string
  checked: boolean
}

type CartState = {
  message: string
  auctionswon: any
  auctionsopen: any
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
  auctionsWonChecked: null | ItemWonList[]
  totalAmount: any
  showccfees: any
  ccfeespercentage: any
  checkoutStatus: "idle" | "loading" | "succeeded" | "failed"
  paymentprocessorisvalid: any
  paymentprocessorcode: any
  paymentprocessorinfo: null | paymentprocessorinfo
  usecardtitle: any
  usecard: any
}

/** Please remove below test item when you have something in items won
 * added for testing
 */
const initialState: CartState = {
  message: "",
  auctionswon: [],
  auctionsopen: [],
  status: "idle",
  error: null,
  auctionsWonChecked: null,
  totalAmount: 0,
  showccfees: false,
  ccfeespercentage: 0,
  checkoutStatus: "idle",
  paymentprocessorisvalid: false,
  paymentprocessorcode: 0,
  paymentprocessorinfo: null,
  usecardtitle: '',
  usecard: false,
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

export const fetchCartData = createAsyncThunk(
  "cart/fetchCartData",
  async (url: string) => {
    const token = localStorage.getItem("token")
    const response = await client.get(url, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    const parsedData = JSON.parse(response.jsonString)
    console.log(parsedData)
    return parsedData
  }
)

export const sendCheckoutData = createAsyncThunk(
  "cart/sendCheckoutData",
  async (config: any) => {
    const token = localStorage.getItem("token")
    const response = await client.post(
      HOST + CHECKOUT_CART,
      {
        ...config
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    return response
  }
)

export const slice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    updateCart: (state) => {
      // const newItems = state.auctionitems.map((item: any) => {
      //     return {
      //         ...item,
      //         watchlist: Math.random() < 0.5,
      //         openforbid: Math.random() < 0.5
      //     }
      // })
      // state.auctionitems = newItems
    },
    setInitialAuctionsWonChecked: (state) => {
      const newList = state.auctionswon.map((item: any) => ({
        ...item,
        checked: false
      }))
      state.auctionsWonChecked = newList
    },
    setAllAuctionsChecked: (state) => {
      const newList = state.auctionswon.map((item: any) => ({
        ...item,
        checked: true
      }))
      state.auctionsWonChecked = newList
    },
    setAuctionsWonCheckedItem: (state, action) => {
      if (state.auctionsWonChecked && state.auctionsWonChecked.length) {
        const newList = state.auctionsWonChecked?.map((item) => {
          if (item.historyid === action.payload.historyid) {
            return {
              ...item,
              checked: action.payload.checked
            }
          } else {
            return item
          }
        })
        return {
          ...state,
          auctionsWonChecked: newList
        }
      }
    },
    updateTotalAmount: (state, action) => {
      if (state.auctionsWonChecked && state.auctionsWonChecked.length) {
        const item = state.auctionsWonChecked.find(
          (item) => item.historyid === action.payload.historyid
        )
        if (item) {
          const amt = item.amount * item.quantity
          if (action.payload.checked) {
            state.totalAmount = state.totalAmount + amt
          } else {
            state.totalAmount = state.totalAmount - amt
          }
        }
      }
    },
    resetStatus: (state) => {
      return { ...initialState };
    },
    resetTotalAmount: (state, action) => {
      state.totalAmount = 0
    },
    updateAuctionsWon: (state, action) => {
      const auctionsWonList = state.auctionswon.concat(action.payload.item)
      state.auctionswon = auctionsWonList
    }
  },
  extraReducers: (builder) => {
    builder.addCase("cart/fetchCartData/pending", (state, action) => {
      state.status = "loading"
    })
    builder.addCase("cart/fetchCartData/fulfilled", (state, action: any) => {
      state.status = "succeeded"
      state.auctionswon = []

      Object.keys(state).forEach((key) => {
        if (key in action.payload) {
          if (hasKey(state, key)) {
            let value = action.payload[key]
            state[key] = value
          }
        }
      })
      if (state.auctionswon?.length === 0) {
        state.auctionsWonChecked = []
      }

      const newList = state.auctionswon.map((item: any) => ({
        ...item,
        checked: true
      }))
      state.auctionsWonChecked = newList
    })
    builder.addCase("cart/fetchCartData/rejected", (state, action: any) => {
      state.status = "failed"
      state.error = action.error.message
    })
    builder.addCase("checkout/sendCheckoutData/pending", (state, action) => {})
    builder.addCase(
      "checkout/sendCheckoutData/fulfilled",
      (state, action) => {}
    )
    builder.addCase("user/updateStateOnLogout", (state) => {
      return {...initialState}
    })
  }
})

export const {
  updateCart,
  setInitialAuctionsWonChecked,
  setAuctionsWonCheckedItem,
  setAllAuctionsChecked,
  updateTotalAmount,
  updateAuctionsWon,
  resetTotalAmount,
  resetStatus
} = slice.actions
export default slice.reducer
