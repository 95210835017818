import {CHECKOUT_CART, getEndPoint, HOST, UPDATE_CARD, USER_PAYMENT_METHOD} from "./enpoints"
import { client } from "./fetchData"
import {createAsyncThunk} from "@reduxjs/toolkit";


const userpaymentmethod = () => {
  const token = localStorage.getItem("token")
  return client.get(getEndPoint(USER_PAYMENT_METHOD), {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

const sendUpdateCard = (body) => {
  const token = localStorage.getItem("token")
  return client.post(getEndPoint(UPDATE_CARD), body, {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

const deleteCard = () => {
  const token = localStorage.getItem("token")
  return client.delete(getEndPoint(UPDATE_CARD), {
    headers: {
      Authorization: "Bearer " + token
    }
  })
}

export { userpaymentmethod, deleteCard, sendUpdateCard }
