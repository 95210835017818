import { funnel, heart, list, refreshOutline } from "ionicons/icons";
import React, {useEffect, useState} from "react";

import {
  IonBadge,
  IonCol,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSearchbar,
  useIonViewDidEnter
} from "@ionic/react";

import { IconCard } from "../../components/Icons/IconCard";
import {
  fetchAuctionItemsData,
  sortOptions,
  useAuctionItemsState,
  useSortValue
} from "../../redux/slices/auctionItemsSlice";
import FlexRow from "../CommonStyles/FlexRow";
import PageBodyContainer from "../CommonStyles/PageBodyContainer";
import Text from "../CommonStyles/Text";
import { CardViewItems } from "./CardViewItems";
import { ListViewItems } from "./ListItems";
import { SlideOutMenu } from "./SlideOutMenu";
import {
  useFetchAuctionItems,
  useFilterBySearchTerm,
  useFilterByTagsAndFilters,
  useFiltersReducer,
  useSavedListView,
  useSortItems,
} from "./helpers";
import {
  HeaderButton, HeartIconButton,
  ListItemHeader,
  ListViewGrid,
  SearchBarContainer,
} from "./styledComponents";
import {useAuthRefresh} from "../../hooks/authenicationHook";
import {AUCTION_ITEMS_ENDPOINT, CART_ENDPOINT, getEndPoint} from "../../util/api/enpoints";
import {useDispatch, useSelector} from "react-redux";
import {fetchCartData} from "../../redux/slices/cartSlice";
import {Link} from "react-router-dom";


export interface AuctionItem {
  rownumber: number;
  itemid: number;
  name: string;
  description: string;
  tags: string;
  lastbid: number;
  open: string;
  closing: string;
  opencd: string;
  opencst: string
  closingcd: string;
  closingcst: string;
  watchlist: boolean;
  openforbid: boolean;
  buyitnowavailable: boolean;
  buyitnowonly: boolean;
  allowautobid: boolean;
  buyitnowprice: number;
  bidstatus: string;
  currentlywinningbid: boolean;
  accountbiddingon: boolean;
  quantityunlimited: boolean;
  imageurl: string;
}

export enum SlideOutState {
  closed,
  filters,
  sort,
}

export type UserBidStatus =
  | "highBidder"
  | "losingBidder"
  | "winner"
  | "default";

const AuctionItemsPage: React.FC = () => {
  const eventState = useSelector((state: any) => state.event)
  useAuthRefresh();
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT)))
    dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
  }, [])

  const [listView, setListView] = useState(false);
  const [slideoutState, setSlideoutState] = useState<SlideOutState>(
    SlideOutState.closed
  );
  const [filteredItems, setFilteredItems] = useState<AuctionItem[]>([]);
  const [searchedItems, setSearchedItems] = useState<AuctionItem[]>([]);
  const [sortedItems, setSortedItems] = useState<AuctionItem[]>([]);
  const accountStatus = useSelector((state: any) => state.user.accountstatus);
  const [searchString, setSearchString] = useState("");
  const [badgeCount, setBageCount] = useState(0);
  
  const [filtersState, dispatchComponent] = useFiltersReducer();
  const { status: auctionItemsFetchState, auctionitems: auctionItems } = useAuctionItemsState();


  useFetchAuctionItems();
  useSavedListView(setListView);
  useFilterBySearchTerm({ searchString, auctionItems, setSearchedItems });
  useFilterByTagsAndFilters({ filtersState, setFilteredItems, searchedItems });
  useSortItems({ filteredItems, setSortedItems });

  const toggleView = () => {
    localStorage.setItem("cardView", listView ? "card" : "list");
    setListView(!listView);
  };

  const isAnyItemIsLiveOnly = () : boolean => {
    return auctionItems.some(item =>
      item.itemisliveonly === true
    )
  }

  const showHideFilters = () => {
    setSlideoutState(
      slideoutState === SlideOutState.closed
        ? SlideOutState.filters
        : SlideOutState.closed
    )
  };

  const renderCardItems = () => {
    const showSearchItems = searchString.length > 0;

    if (
      (showSearchItems && searchedItems.length === 0) ||
      (filtersState.tags.length > 0 && filteredItems.length === 0) ||
      (filtersState.filters.length > 0 && filteredItems.length === 0)
    )
      return handleNoResults(false);
    else {
      return (
        <CardViewItems items={sortedItems} state={auctionItemsFetchState} />
      );
    }
  };

  const renderListItems = () => {
    const showSearchItems = searchString.length > 0;

    if (
      (showSearchItems && searchedItems.length === 0) ||
      (filtersState.tags.length > 0 && filteredItems.length === 0) ||
      (filtersState.filters.length > 0 && filteredItems.length === 0)
    )
      return handleNoResults(true);
    else {
      return (
        <ListViewItems items={sortedItems} state={auctionItemsFetchState} />
      );
    }
  };

  const handleNoResults = (listView: boolean) => {
    if (listView) {
      return (
        <IonRow>
          <IonCol>
            <Text fontSize="20px">No Results Found</Text>
          </IonCol>
        </IonRow>
      );
    } else {
      return (
        <Text fontSize="20px" padding="1rem">
          No Results Found
        </Text>
      );
    }
  };

  const onRefreshListClick = () => {
    window.location.reload();
  };

  const handleCheckboxChange = (event: any) => {
    const type = event.target.dataset.filterType;
    dispatchComponent({
      type: type,
      payload: {
        name: event.target.name,
        value: event.target.checked,
      },
    });
  };

  interface RefresherEventDetail {
    complete(): void;
  }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    setTimeout(() => {
      // Any calls to load data go here
      dispatch(
          fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT))
      )
      event.detail.complete();
    }, 1000);
  }

  useEffect(() => {
    var filterCount = 0
    if (filtersState?.tags?.length > 0) {
      filterCount = filtersState?.tags?.length
    }
    if (filtersState?.filters?.length > 0) {
      filterCount += filtersState?.filters?.length
    }
    setBageCount(filterCount)
  }, [filtersState])


  return (
      <>
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent refreshingSpinner={'circular'} refreshingText={'Loading...'}></IonRefresherContent>
        </IonRefresher>

        <SearchBarContainer>
          <HeaderButton color="#FFFFFF" onClick={toggleView}>
            {listView ? (
              <IconCard style={{ width: 24, height: 24, fill: "#D2222D" }} />
            ) : (
              <IonIcon
                slot="icon-only"
                icon={list}
                style={{ width: 28, height: 28, color: "#D2222D" }}
              />
            )}
          </HeaderButton>
          <IonSearchbar
            style={{ height: 44 }}
            className="searchBar"
            placeholder="Search for items"
            value={searchString}
            onIonChange={(e) => setSearchString(e.detail.value)}
            showCancelButton="never"
          ></IonSearchbar>

          <HeaderButton style={{position: 'relative', margin: '5px', width: '40px', height: '40px'}} color="#FFFFFF" onClick={showHideFilters}>
            {badgeCount > 0 &&
                <IonBadge style={{position: 'absolute', fontSize: '8pt', right: '28px', top: '-3px'}} color="dark" >{badgeCount}</IonBadge>
            }
            <IonIcon
              slot="icon-only"
              icon={funnel}
              style={{ width: 24, height: 24, color: "#D2222D" }}
            >
            </IonIcon>

          </HeaderButton>

          <HeaderButton style={{ marginLeft: 10 }} color="#FFFFFF" onClick={onRefreshListClick}>
            <IonIcon
                slot="icon-only"
                icon={refreshOutline}
                style={{ width: 28, height: 28, color: "#D2222D" }}
            />
          </HeaderButton>

          {slideoutState !== SlideOutState.closed &&
              <SlideOutMenu
                  slideoutState={slideoutState}
                  setSlideoutState={setSlideoutState}
                  handleCheckboxChange={handleCheckboxChange}
                  filtersState={filtersState}
                  setItemHasLiveOnly={isAnyItemIsLiveOnly()}
              />
          }

        </SearchBarContainer>

      {/** Card View */}

      {!listView && (
        <FlexRow
          alignItems="center"
          justifyContent="space-evenly"
        >
          {renderCardItems()}
        </FlexRow>
      )}

      {/** List View */}
      {listView && (
        <>
          <ListViewGrid>
            <ListItemHeader guest={accountStatus === 'guest'}>
              {accountStatus !== 'guest' &&
                <IonIcon
                  icon={heart}
                  slot="icon-only"
                  color={"tertiary"}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    justifySelf: "center",
                  }}
                />
              }
              {["Item", "Tags", "Bid", "Closing"].map((header) => {
                return (
                  <Text important fontSize="14px" fontWeight="800">
                    {header}
                  </Text>
                );
              })}
            </ListItemHeader>
            {renderListItems()}
          </ListViewGrid>
        </>
      )}
        <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
          <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
            AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
            <span style={{ color: "red" }}>
                <a><Link style={{ textDecoration: 'none' }} to={"/" + eventState.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
            </span>
          </div>
        </div>
      </>
  );
};

export default AuctionItemsPage;
