import React, {useEffect, useRef, useState} from "react"
import './styles.css'
import styled from "@emotion/styled"
import {
    IonCol,
    IonRow,
    IonText,
    IonToast,
    IonToggle,
    IonGrid,
    IonItem,
    IonModal,
    IonBackdrop,
    IonAlert, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonFooter, IonButton, useIonModal
} from "@ionic/react"

import { usStates } from "../../constants"
import Button from "../CommonStyles/Button"
import InputsContainer from "../CommonStyles/InputsContainer"
import Label from "../CommonStyles/Label"
import './index.css';
import Text from "../CommonStyles/Text"
import {useDispatch, useSelector} from "react-redux";
import {checkTokenOnRefresh, useUserInfo} from "../../redux/slices/userSlice";
import {getEndPoint, USER_PROFILE} from "../../util/api/enpoints";
import NumberFormat from "react-number-format";
import CheckBox from "../../components/CheckBox/Index"
import {Span} from "../AuctionItems/styledComponents";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import {Select} from "../ItemDetails/styledComponents";
import ButtonItem from "../CommonStyles/ButtonItem";
import {Link} from "react-router-dom";
import {loadStripe} from "@stripe/stripe-js";
import {sendUpdateCard, userpaymentmethod} from "../../util/api/userpaymentmethod";


const ModalContent = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  margin-right: 32px;
  height: 32em;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

const CellInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //margin-left: 12px;
  //margin-right: 12px;
  margin-top: 10px;
  //padding: 10px;
`

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 10px;
`
const Input = styled("input")`
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #0000000d;
  outline: none;
  border: none;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`


const FlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`

const MessageModal = ({
                          onDismiss,
                          message,
                      }: {
    onDismiss: (data?: string | null | undefined | number, role?: string) => void;
    message: string;
}) => {
    const inputRef = useRef<HTMLIonInputElement>(null);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Text Messaging Opt-In</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                <IonItem lines={'none'}>
                    <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>You have provided your phone number but have not checked the box to opt-in to event related text messages.</IonLabel>
                </IonItem>
                <IonItem lines={'none'}>
                    <IonLabel class={'ion-text-wrap'} style={{ fontSize: '18px'}}>Are you sure you sure you want to proceed?</IonLabel>
                </IonItem>
            </IonContent>
            <IonFooter>
                <IonRow style={{marginBottom: '20px'}}>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'cancel')} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'secondary'}>
                            Go Back
                        </IonButton>
                    </IonCol>
                    <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                        <IonButton onClick={() => onDismiss(inputRef.current?.value, 'ok')} size="large" style={{ width: '90%' }} expand="block" fill="solid" color={'primary'}>
                            Proceed without Text Messages
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonFooter>
        </IonPage>
    );
};



const StepCreditCard: React.FC<{
    formFields: {
        cellphone: string
        allowtexting: boolean
        bidanonymously: boolean
    }
    goToNextView: any
    goToPreviousView: any
    needscardtoregister: boolean
}> = (props) => {
    const eventData = useSelector((state: any) => state.event)
    const [replaceCardTrigger, setReplaceCardTrigger] = useState<boolean>(false)
    const [showStripeForm, setShowStripeForm] = useState<boolean>(false)
    const [toastObj, setToast] = useState({ display: false, message: '', goBack: false });
    const [stripeObj, setStripeObj] = useState(null)
    const [stripeElement, setStripeElement] = useState(null)
    const [reloadData, setReloadData] = useState(0)
    const [orderTotal, setOrderTotal] = useState(100)
    const [stripePaymentElement, setStripePaymentElement] = useState(null)
    const [processorToken, setProcessorToken] = useState('');
    const [toastState, setToastState] = useState({
        display: false,
        goback: false,
        message: ''
    })
    const [userPaymentMethodData, setUserPaymentMethodData] = React.useState({
        cardmessage: '',
        message: '',
        paymentprocessorcode: 0,
        paymentprocessorisvalid: true,
        paymentprocessorinfo: {
            stripeaccountid: '',
            stripeapikey: '',
        },
        showremovedbutton: false,
        showreplacedbutton: false,
        showstripeiframe: false,
        status: '',
        type: '',
        userpaymentmethodtitle: ''
    })

    useEffect(() => {
        console.log(props.needscardtoregister)
    }, [props.needscardtoregister])

    useEffect(() => {
        if (replaceCardTrigger) {
            setShowStripeForm(true)
        }
        else {
            setShowStripeForm((userPaymentMethodData?.paymentprocessorcode == 2 && userPaymentMethodData?.showstripeiframe))
        }
    }, [userPaymentMethodData?.paymentprocessorcode, userPaymentMethodData?.showstripeiframe, replaceCardTrigger])

    useEffect(() => {
        if (userPaymentMethodData?.paymentprocessorinfo?.stripeapikey && userPaymentMethodData?.paymentprocessorinfo?.stripeapikey.length > 0) {
            loadStripeAccount()
        }
    }, [userPaymentMethodData?.paymentprocessorinfo?.stripeapikey, userPaymentMethodData?.paymentprocessorinfo?.stripeaccountid])

    const loadStripeAccount = async () => {
        const stripeTempObj = await loadStripe(userPaymentMethodData?.paymentprocessorinfo?.stripeapikey, {
            stripeAccount: userPaymentMethodData?.paymentprocessorinfo?.stripeaccountid,
        });
        setStripeObj(stripeTempObj);
    }

    const [errorModal, setErrorModal] = useState({
        display: false,
        message: '',
    })

    const options = {
        mode: 'setup',
        // amount: orderTotal * 100,
        paymentMethodCreation: 'manual',
        currency: 'usd',
        paymentMethodTypes: ['card'],
        //confirm = true,
        setupFutureUsage: 'off_session',
    };

    useEffect(() => {
        if (stripeObj) {
            const elements = stripeObj.elements(options);
            setStripeElement(elements)
            const paymentElement = elements.create('payment', {
                layout: {
                    type: 'tabs',
                    defaultCollapsed: false,
                },
            });
            paymentElement.mount('#payment-element');

            paymentElement.on('change', async function(event) {
                if (event.complete) {
                    await elements.submit();
                    const {error, confirmationToken} = await stripeObj.createConfirmationToken({elements: elements})
                    if (confirmationToken) {
                        setProcessorToken(confirmationToken.id)
                    }
                    else if (error) {
                        const errorMessage = error?.message
                        setErrorModal({
                            display: true,
                            message: errorMessage,
                        })
                    }
                    console.log(confirmationToken)
                    console.log(error)
                }
            });
            setStripePaymentElement(paymentElement)
        }
    }, [stripeObj])

    useEffect(() => {
        const getUserData = async () => {
            await dispatch(
                checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
            )
        }
        const getpaymentmethoddata = async () => {
            const response = await userpaymentmethod()
            const parsedData = JSON.parse(response.jsonString)
            console.log(parsedData)
            setUserPaymentMethodData(parsedData)
        }

        getUserData()
        getpaymentmethoddata()
    }, [])

    // here scott


    const [present, dismiss] = useIonModal(MessageModal, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        message: '',
    })

    const dispatch = useDispatch()
    const user = useUserInfo()
    const eventState = useSelector((state: any) => state.event)

    useEffect(() => {
        const getUserData = async () => {
            await dispatch(
                checkTokenOnRefresh({ url: getEndPoint(USER_PROFILE) })
            )
        }
        getUserData()
    }, [dispatch, checkTokenOnRefresh, getEndPoint, USER_PROFILE])

    const userData = useSelector((state: any) => state.user)

    const clickUpdate = async () => {
        if (!props.needscardtoregister && (!processorToken || processorToken.length <= 0)) {
            props.goToNextView()
        }
        else if (!processorToken || processorToken.length <= 0) {
            return setToastState({ display: true, goback: false, message: `Please enter a valid credit card.`})
        }
        else {
            const checkoutObj = {
                processortoken: processorToken
            }
            const response = await sendUpdateCard(checkoutObj)
            const parsedData = JSON.parse(response.jsonString)
            let title = 'An error has occurred!'
            let message = parsedData.message
            if (parsedData.status === 'success') {
                if (stripePaymentElement) {
                    stripePaymentElement.clear()
                }
                title = ''
            }
            if (message.length > 0) {
                return setToastState({ display: true, goback: true, message: message})
            }
            else {
                props.goToNextView()
            }
        }
    }

    return (
    <>
        <IonToast
            isOpen={toastState.display}
            onDidDismiss={() => {
                setToastState({
                    display: false,
                    goback: false,
                    message: ''
                })
            }}
            cssClass={'custom-toast'}
            message={toastState.message}
            duration={3000}
        />
        <Section>
            <IonGrid>
                <IonRow>
                    <IonCol className="ion-align-items-start" size="12">
                        <IonItem lines="none">
                            <div dangerouslySetInnerHTML={{ __html: userPaymentMethodData?.userpaymentmethodtitle }} />
                        </IonItem>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <IonGrid style={{width: '100%'}}>
                <IonRow>
                    <IonCol className="ion-align-items-start" size={"12"}>
                        <div>
                            <div style={{ width: "100%" }} id="payment-element"></div>
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </Section>
      <FlexRow>
        <Button
          height="40px"
          width="200px"
          backgroundColor="white"
          color="black"
          style={{ zIndex: 1 }}
          onClick={props.goToPreviousView}
        >
          Back
        </Button>
        <Button opacity={(props.needscardtoregister == false || processorToken?.length > 0) ? 1 : .4} disabled={(props.needscardtoregister == false || processorToken?.length > 0) ? false : true}  style={{ zIndex: 1 }} height="40px" width="200px" onClick={() => { clickUpdate() }}>
          Next
        </Button>
      </FlexRow>
      <FlexRow>
        <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
            <div style={{verticalAlign: 'middle', color: '#666', marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
                AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
                <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventData.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
                </span>
            </div>
        </div>
      </FlexRow>
    </>
  )
}

export default StepCreditCard
