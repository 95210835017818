import {heart, heartOutline, refreshOutline} from "ionicons/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {useAuthRefresh} from "../../hooks/authenicationHook";
import {IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRefresher, IonRefresherContent, IonRow} from "@ionic/react";

import IconAdd from "../../components/Icons/IconAdd";
import IconRemove from "../../components/Icons/IconRemove";
import { fetchAuctionItemDetailsData, updateItemDetailsHistoryId, resetItemDetailsState } from "../../redux/slices/auctionItemDetailsSlice";
import {fetchAuctionItemsData, toggleWatchlistItem} from "../../redux/slices/auctionItemsSlice";
import { fetchBidHistoryData } from "../../redux/slices/bidHistorySlice";
import {resetStatus, fetchCartData} from "../../redux/slices/cartSlice";
import {resetOrderStatus} from "../../redux/slices/ordersSlice";
import store, { AppState } from "../../redux/store";
import {
  AUCTION_ITEM_DETAIL_ENDPOINT,
  BID_HISTORY,
  WATCH_LIST,
  getEndPoint, AUCTION_ITEMS_ENDPOINT, CART_ENDPOINT,
} from "../../util/api/enpoints";
import { submitBid } from "../../util/api/submitBid";
import { UserBidStatus } from "../AuctionItems/Index";
import { HeartIconButton } from "../AuctionItems/styledComponents";
import ButtonItem from "../CommonStyles/ButtonItem";
import Text from "../CommonStyles/Text";
import { CurrentBid } from "./CurrentBid";
import Img from "./Img";
import {
  BuyItNowContainer,
  Option,
  PageContainer,
  Select,
} from "./styledComponents";
import {updateStateOnLogout} from "../../redux/slices/userSlice";
import {disconnected} from "../../redux/slices/signalrSlice";
import {Link} from "react-router-dom";
import {DateTime} from "luxon";
import styled from "@emotion/styled";
import {OverlayEventDetail} from "@ionic/react/dist/types/components/react-component-lib/interfaces";

type Params = {
  eventcode: string
  id: string;
  popup?: string;
};

const ModalContent = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  h1 {
    font-family: var(--ion-font-family);
    font-size: 24px/33px;
    font-weight: bold;
  }
  p {
    font-family: var(--ion-font-family);
  }
  letter-spacing: 0px;
  color: #333333;
`

const ItemDetailsPage: React.FC = () => {
  const eventState = useSelector((state: any) => state.event)
  const local = DateTime.local()
  useAuthRefresh();

  const getLocalDateString = (dateString) => {
    const centralDate = DateTime.fromISO(dateString,{zone: 'America/Chicago'})
    const localDate = centralDate.setZone(local.zoneName)
    const localDateString = localDate.toString()
    return localDateString
  }

  const accountStatus = useSelector((state: any) => state.user.accountstatus);

  useEffect(() => {
    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
      elementScroll.scroll(0,0)
    }

    dispatch(
        resetItemDetailsState()
    )
    dispatch(
        fetchAuctionItemDetailsData({
          url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
          itemId: params.id,
        })
    );
    dispatch(
        fetchBidHistoryData({
          url: getEndPoint(BID_HISTORY),
          itemId: params.id,
        })
    );
    dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
  }, [])

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams<Params>();
  const userId = useSelector((state: any) => state.user.userid);
  const authorizebidder = useSelector((state: any) => state.user.authorizebidder)

  useEffect(() => {
    if (params.popup === 'popup') {
      setBuyItNow(true)
    }
  }, [params.popup])

  // history.listen((location, action) => {
  //   window.scrollTo(0, 0)
  // })

  const itemArr = useSelector((state: any) =>
    state.auctionItems.auctionitems.filter(
      (item: any) => item.itemid === parseInt(params.id)
    )
  );

  const [quantitySelected, setQuantitySelected] = useState(1);
  const item = itemArr[0];

  const [listState, setListState] = useState({
    itemDetails: false,
    bidHistory: false,
  });

  const [buyItNow, setBuyItNow] = useState(false);
  const [popupBuyItNowCouner, setPopupBuyItNowCounter] = useState(0);

  const auctionItemDetailsState = useSelector(
    (state: AppState) => state.auctionItemDetails
  );

  const currentBidState = useSelector(
      (state: AppState) => state.auctionItemDetails.currentbid
  );

  useEffect(() => {
    dispatch(
        fetchBidHistoryData({
          url: getEndPoint(BID_HISTORY),
          itemId: params.id,
        })
    );
  }, [auctionItemDetailsState.currentbid])

  const bidHistoryData = useSelector((state: any) => state.bidHistory);

  const stillOpen =  item?.closingcst ? moment().diff(moment(getLocalDateString(item.closingcst))) < 0 : true
  var userBidStatus: UserBidStatus = "default"
  if (item && auctionItemDetailsState.quantityavailable > 0) {
    if (item.currentlywinningbid && item.accountbiddingon && stillOpen) {
      userBidStatus = "highBidder"
    }
    else if (item.currentlywinningbid && item.accountbiddingon && !stillOpen) {
      userBidStatus = "winner"
    }
    else if (!item.currentlywinningbid && item.accountbiddingon) {
      userBidStatus = "losingBidder"
    }
  }

  const calculateTime = (ago: string) => {
    const then: any = moment(ago)
    const now: any = moment()
    const diff = moment.duration(now.diff(then))
    var minutes = Math.floor(diff.minutes())
    var hours = diff.hours()
    var days = diff.days()
    return `${days}d ${hours}h ${minutes}m`;
  };

  const closingTime = (closing: string) => {
    const then: any = moment(closing);
    const now: any = moment();
    const diff = moment.duration(then.diff(now));
    var days = Math.floor(diff.asDays());
    var hours = diff.hours();
    var min = diff.minutes()
    if (days < 1) {
      return `${hours}h ${min}m`
    }
    else if (days < 1 && hours < 1 && min < 1) {
      return `1m`
    }
    return `${days}d ${hours}h`;
  };

  const calculateBidHistory = () => {
    var num = 0;
    if (item.numberofbids > 0) {
      num = item.numberofbids;
    } else if (bidHistoryData && bidHistoryData.history) {
      num = bidHistoryData.history.length;
    }
    return num;
  };

  useEffect(() => {
    /**
     * why do we need this if condition commenting out for now
     */
    // if (parseInt(auctionItemDetailsState.itemid) !== parseInt(params.id)) {}
    dispatch(
        resetItemDetailsState()
    )
    dispatch(
      fetchAuctionItemDetailsData({
        url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
        itemId: params.id,
      })
    );
    dispatch(
      fetchBidHistoryData({
        url: getEndPoint(BID_HISTORY),
        itemId: params.id,
      })
    );
  }, [params.id]);

  const toggleListState = (name: any, newVal: boolean) => {
    const { itemDetails, bidHistory } = listState;

    if (name === "itemDetails") {
      if (newVal === true) {
        if (bidHistory) {
          setListState({ itemDetails: newVal, bidHistory: false });
        } else {
          setListState({ itemDetails: newVal, bidHistory });
        }
      } else {
        setListState({ itemDetails: newVal, bidHistory });
      }
    } else if (name === "bidHistory") {
      if (newVal === true) {
        if (itemDetails) {
          setListState({ itemDetails: false, bidHistory: newVal });
        } else {
          setListState({ bidHistory: newVal, itemDetails });
        }
      } else {
        setListState({ bidHistory: newVal, itemDetails });
      }
    }
  };

  const placeBid = () => {
    if (auctionItemDetailsState?.needscardtobid) {
      // Load card page
      navigate("/" + params.eventcode + "/account-update-payment-method/" + params.id );
    }
    else if (item?.quantityavailable > 0 || item?.unlimitedquantity) {
      navigate("/" + params.eventcode + "/place-bid/" + params.id );
    }
  };

  const logout = () => {
    const elementScroll = document.getElementById("scrollBody")
    if (elementScroll) {
      elementScroll.scroll(0,0)
    }

    dispatch(updateStateOnLogout())
    const token = localStorage.getItem("token")
    localStorage.removeItem("token")
    dispatch(disconnected())
    dispatch({
      type: "DISCONNECT_SIGNALR"
    })

    navigate('/' + params.eventcode)
  }
  const clickBuyItNow = (e: any) => {
    if (auctionItemDetailsState?.needscardtobid) {
      // Load card page
      navigate("/" + params.eventcode + "/account-update-payment-method/auction-items/" + params.id );
    }
    else if (item?.quantityavailable > 0 || item?.unlimitedquantity) {
      e.stopPropagation()
      setBuyItNow(!buyItNow);
    }
  };

  const changeQuantitySelected = (e: any) => {
    setQuantitySelected(e.target.value);
  };

  const buildQuantityList = () => {
    const arr = [];
    for (let i = 1; i <= calcQuantityAvailableDropdown(auctionItemDetailsState); i++) {
      arr.push(
        <Option key={i} value={i}>
          {i}
        </Option>
      );
    }
    return arr;
  };

  const calcaulateTotal = () => {
    const total = quantitySelected * auctionItemDetailsState.buyitnowprice;
    return (Math.round(total * 100) / 100).toFixed(2);
  };

  const getDescription = (item) => {
    if (item.longdescription && item.longdescription.length > 0) {
      return item.longdescription
    }
    else {
      return item.description
    }
  }

  const clickPopupBuyItNow = async () => {
    setPopupBuyItNowCounter(popupBuyItNowCouner + 1);
    if (popupBuyItNowCouner === 0) {
      const response = await submitBid({
        itemid: item.itemid,
        userid: userId,
        bid: auctionItemDetailsState.currentbid,
        buyitnow: true,
        quantity: Number(quantitySelected),
      });
      const responseJSON = JSON.parse(response.jsonString)
      dispatch(resetStatus())
      dispatch(resetOrderStatus())
      if (responseJSON && responseJSON.historyid) {
        store.dispatch(
            updateItemDetailsHistoryId({
              itemid: item.itemid,
              historyid: responseJSON.historyid
            })
        )
      }
    }
  }

  const calcQuantityAvailable = (auctionItemDetailsState: any) => {
    return auctionItemDetailsState.quantityavailable
  }

  const calcQuantityAvailableDropdown = (auctionItemDetailsState: any) => {
    if (item?.unlimitedquantity) {
      return item?.maxpurchasequantity
    }
    else {
      if (auctionItemDetailsState.quantityavailable > item?.maxpurchasequantity && item?.maxpurchasequantity > 0) {
         return item?.maxpurchasequantity
      }
      else {
        return auctionItemDetailsState.quantityavailable
      }
    }
  }

  const continueBidding = () => {
    setBuyItNow(false);
    setPopupBuyItNowCounter(0);
    dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))
  };

  const closePopup = () => {
    setBuyItNow(false);
    setPopupBuyItNowCounter(0);
  };

  const onWatchListClick = (itemid: number) => (e: any) => {
    e.stopPropagation();
    dispatch(toggleWatchlistItem({ url: getEndPoint(WATCH_LIST), itemid }));
  };

  const onRefreshListClick = (itemid: number) => (e: any) => {
    e.stopPropagation();
    window.location.reload();
  };

  const goToCheckout = () => {
    // console.log(auctionItemDetailsState)
    // dispatch(
    //   updateAuctionsWon({
    //     item: {
    //       itemid: auctionItemDetailsState.itemid,
    //       historyid: auctionItemDetailsState.historyid,
    //       name: auctionItemDetailsState.name,
    //       description: auctionItemDetailsState.description,
    //       itemnum: auctionItemDetailsState.itemnum,
    //       quantity: quantitySelected,
    //       amount: auctionItemDetailsState.buyitnowprice,
    //       // amount: item.amount,
    //       location: "",
    //       pickinstructions: "",
    //       checked: true,
    //     },
    //   })
    // );
    // dispatch(setInitialAuctionsWonChecked());
    //
    // dispatch(
    //   setAuctionsWonCheckedItem({
    //     id: auctionItemDetailsState.itemid,
    //     checked: true,
    //   })
    // );
    //
    // // Must reset the cart amount for buy it now of it duplicates the total
    // dispatch(resetTotalAmount({}))
    // dispatch(
    //   updateTotalAmount({ id: auctionItemDetailsState.itemid, checked: true })
    // );

    dispatch(fetchCartData(getEndPoint(CART_ENDPOINT)))

    // history.push("/" + params.eventcode + "/checkout-item/" + auctionItemDetailsState.historyid);
    navigate("/" + params.eventcode + "/cart");
  };

  interface RefresherEventDetail {
    complete(): void;
  }

  const onMaxBid = () => {
    // if (item?.quantityavailable > 0 || item?.unlimitedquantity) {
    navigate("/" + params.eventcode + "/auto-bid/" + params.id );
    // }
  }

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {

    setTimeout(() => {
      // dispatch(
      //     resetItemDetailsState()
      // )
      // dispatch(
      //     fetchAuctionItemsData(getEndPoint(AUCTION_ITEMS_ENDPOINT))
      // )
      // dispatch(
      //     fetchAuctionItemDetailsData({
      //       url: getEndPoint(AUCTION_ITEM_DETAIL_ENDPOINT),
      //       itemId: params.id,
      //     })
      // );
      // dispatch(
      //     fetchBidHistoryData({
      //       url: getEndPoint(BID_HISTORY),
      //       itemId: params.id,
      //     })
      // );
      
      window.location.reload();
      event.detail.complete();
    }, 1000);
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    setBuyItNow(false);
    setPopupBuyItNowCounter(0);
  }

  const checkAccountStatus = () => {
    return (auctionItemDetailsState.accountisautobid && userBidStatus !== "winner" && userBidStatus === "highBidder")
  }

  return (
      <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent refreshingSpinner={'circular'} refreshingText={'Loading...'}></IonRefresherContent>
      </IonRefresher>
      <IonGrid>
        {item && (
          <>
            <IonRow>
              <IonCol style={{ position: "relative" }}>
                <Img
                  alt="image"
                  buyitnow={buyItNow}
                  src={item.imageurl}
                ></Img>
                {accountStatus !== 'guest' &&
                  <HeartIconButton
                    active={item.watchlist}
                    inset="16px"
                    buyitnow={buyItNow}
                    onClick={onWatchListClick(item.itemid)}
                  >
                    <IonIcon
                      icon={item.watchlist ? heart : heartOutline}
                      slot="icon-only"
                      color={item.watchlist ? "tertiary" : "medium"}
                      style={{ width: 30, height: 30 }}
                    />
                  </HeartIconButton>
                }
                <HeartIconButton
                    active={true}
                    inset="16px"
                    top="60px"
                    buyitnow={buyItNow}
                    onClick={onRefreshListClick(item.itemid)}
                >
                  <IonIcon
                      icon={refreshOutline}
                      slot="icon-only"
                      color={"medium"}
                      style={{ width: 30, height: 30 }}
                  />
                </HeartIconButton>
              </IonCol>
            </IonRow>

            {/** Name and price row */}
            <IonRow>
              <IonCol size="9">
                <Text fontSize="16px" fontWeight="800">
                  {auctionItemDetailsState.name}
                </Text>
              </IonCol>
              <IonCol size="3">
                <Text
                  fontSize={
                    auctionItemDetailsState.quantityavailable > 0
                      ? "12px"
                      : "14px"
                  }
                  fontWeight={
                    auctionItemDetailsState.quantityavailable > 0
                      ? "300"
                      : "900"
                  }
                  color={
                    auctionItemDetailsState.quantityavailable > 0
                      ? "#666666"
                      : "#000000"
                  }
                  textAlign="right"
                >
                  {item.bidstatus === "biddingclosed"
                    ? "Bidding Closed"
                    : item?.quantityavailable > 0 || item?.unlimitedquantity
                    ? closingTime(getLocalDateString(item.closingcst))
                    : "Sold Out"}
                </Text>
              </IonCol>
              <IonCol size="9">
                {auctionItemDetailsState.donatedby &&(<Text fontSize="12px" fontWeight="300" color="#666666">
                  Donated By: {auctionItemDetailsState.donatedby}</Text>)}
              </IonCol>
              <IonCol size="3" className="ion-align-self-end">
                <Text
                  fontSize="12px"
                  fontWeight="300"
                  color="#666666"
                  padding="0 0 0 5px"
                  textAlign="end"
                >
                  {item?.unlimitedquantity === true
                      ? "Unlimited Quantity"
                      : ""}

                  {item?.unlimitedquantity === false
                    ? calcQuantityAvailable(auctionItemDetailsState) + " Available"
                    : ""}
                </Text>
              </IonCol>
            </IonRow>

            {/**Tags Row */}
            <IonRow>
              {item.tags && item.tags.split(",").map((tag: any) => (
                <IonCol style={{ flexGrow: 0 }} key={tag}>
                  <Text
                    fontSize="12px"
                    borderRadius="10px"
                    backgroundColor="#dbdbdb"
                    padding="4px"
                    margin="2px"
                    style={{whiteSpace: 'nowrap'}}
                  >
                    {tag}
                  </Text>
                </IonCol>
              ))}
            </IonRow>

            {/** MISC */}

            <IonRow>
              <IonCol>
                <Text fontSize="14px" fontWeight="300" color="#666666">
                  {auctionItemDetailsState.description}
                </Text>
              </IonCol>
            </IonRow>

            {/** Group 1 */}
            {!auctionItemDetailsState.buyitnowonly && (
              <IonRow className="ion-align-items-between ion-padding-top ion-padding-bottom">
                <CurrentBid
                  currentBid={auctionItemDetailsState.currentbid}
                  status={userBidStatus}
                  quantity={auctionItemDetailsState.quantityavailable}
                  numOfBids={calculateBidHistory()}
                  minimumBidIncrease={
                    auctionItemDetailsState.minimumbidincrease
                  }
                  isPriceless={auctionItemDetailsState.ispriceless}
                  fairMarketValue={auctionItemDetailsState.fairmarketvalue}
                  onMaxBid={onMaxBid}
                  accountisautobid={auctionItemDetailsState.accountisautobid}
                  accountautobidamount={auctionItemDetailsState.accountautobidamount}
                  accountautobidincrement={auctionItemDetailsState.accountautobidincrement}
                />
              </IonRow>
            )}

            {/** GROUP 2  */}
            {((auctionItemDetailsState.buyitnowavailable ||
              auctionItemDetailsState.buyitnowonly)) && (
              <IonRow
                className="ion-align-items-between"
              >
                <IonCol size="6" style={{ paddingBottom: 0 }}>
                  <Text fontWeight="800" fontSize="26px">
                    ${auctionItemDetailsState.buyitnowprice}
                  </Text>
                </IonCol>
                <IonCol size="2" style={{ paddingBottom: 0 }}></IonCol>
                <IonCol
                  size="4"
                  className="ion-align-self-end"
                  style={{ paddingBottom: 0 }}
                >
                  {/*<Text textAlign="end" fontWeight="800" fontSize="20px">*/}
                  {/*  {(auctionItemDetailsState.ispriceless) && ("Priceless")}*/}
                  {/*  {(!auctionItemDetailsState.ispriceless && auctionItemDetailsState.fairmarketvalue > 0) && (*/}
                  {/*      '$' + auctionItemDetailsState.fairmarketvalue*/}
                  {/*  )}*/}
                  {/*</Text>*/}
                </IonCol>
              </IonRow>
            )}
            {(auctionItemDetailsState.buyitnowavailable ||
              auctionItemDetailsState.buyitnowonly) && (
              <IonRow
                style={{ borderBottom: "1px solid rgba(102, 102, 102, 0.3)" }}
              >
                <IonCol size="6" style={{ paddingTop: 0, paddinBottom: 0 }}>
                  <Text fontWeight="300" fontSize="12px" color="#666666">
                    Buy It Now
                  </Text>
                </IonCol>
                <IonCol
                  size="2"
                  style={{ paddingTop: 0, paddinBottom: 0 }}
                ></IonCol>
                <IonCol
                  size="4"
                  className="ion-align-self-end"
                  style={{ paddingTop: 0, paddinBottom: 0 }}
                >
                  {/*<Text*/}
                  {/*  textAlign="end"*/}
                  {/*  fontWeight="300"*/}                  {/*<Text*/}
                  {/*  textAlign="end"*/}
                  {/*  fontWeight="300"*/}
                  {/*  fontSize="12px"*/}
                  {/*  color="#666666"*/}
                  {/*>*/}
                  {/*  Fair Market Value*/}
                  {/*</Text>
                  {/*  fontSize="12px"*/}
                  {/*  color="#666666"*/}
                  {/*>*/}
                  {/*  Fair Market Value*/}
                  {/*</Text>*/}
                </IonCol>
              </IonRow>
            )}

            {!checkAccountStatus() && item.buyitnowonly === false && item.bidstatus === "biddingopen"
                && authorizebidder && !item.itemisliveonly && (
              <IonRow>
                <IonCol>
                  <ButtonItem onClick={placeBid}
                    opacity={
                      auctionItemDetailsState.quantityavailable > 0
                        ? "100.0"
                        : "0.55"
                    }
                    height="45px"
                  >
                    Place Bid
                  </ButtonItem>
                </IonCol>
              </IonRow>
            )}

            {(!authorizebidder && accountStatus !== 'guest') &&
                <IonRow className="ion-align-items-center">
                  <IonCol>
                    <Text textAlign={"center"} fontSize="22px" fontWeight="800">
                      Not authorized to bid. Contact the event organizer.
                    </Text>
                  </IonCol>
                </IonRow>
            }

            {authorizebidder && item.itemisliveonly && (
                <IonRow className="ion-align-items-center">
                  <IonCol>
                    <Text textAlign={"center"} fontSize="22px" fontWeight="800">
                      Live Auction
                    </Text>
                  </IonCol>
                </IonRow>
            )}

            {checkAccountStatus()  && (
                    <IonRow>
                      <IonCol>
                        <ButtonItem onClick={onMaxBid}
                                    opacity={
                                      auctionItemDetailsState.quantityavailable > 0
                                          ? "100.0"
                                          : "0.55"
                                    }
                                    height="45px"
                        >
                          Update Max Bid
                        </ButtonItem>
                      </IonCol>
                    </IonRow>
                )}

            {accountStatus === 'guest' &&
                <IonRow>
                  <IonCol>
                    <ButtonItem onClick={logout} height="45px">
                      Log In to Bid
                    </ButtonItem>
                  </IonCol>
                </IonRow>
            }

            {(auctionItemDetailsState.buyitnowavailable ||
              auctionItemDetailsState.buyitnowonly) && !item.itemisliveonly &&
              auctionItemDetailsState.bidstatus === "biddingopen" && authorizebidder && (
                <IonRow>
                  <IonCol>
                    <ButtonItem
                      onClick={clickBuyItNow}
                      height="45px"
                      backgroundColor={
                        item.buyitnowonly 
                        ? "#C21807"
                        : "#ffffff"}
                      
                      opacity={
                        buyItNow ? "0.10" : (
                        item?.quantityavailable > 0 || item?.unlimitedquantity
                          ? "100.0"
                          : "0.55")
                      }
                      color={
                        item.buyitnowonly 
                        ? "#ffffff"
                        : "#C21807"}
                    >
                      Buy It Now
                    </ButtonItem>
                  </IonCol>
                </IonRow>
              )}

            {/** Item Details Grid */}
            <IonGrid
              style={{ border: "1px solid #dedede", borderRadius: "6px" }}
              onClick={() =>
                toggleListState("itemDetails", !listState.itemDetails)
              }
            >
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <Text fontSize="16px" important fontWeight="800">
                    Item Details
                  </Text>
                </IonCol>
                <IonCol className="ion-align-items-end" size="auto">
                  {!listState.itemDetails && <IconAdd fill="#d2222d"></IconAdd>}
                  {listState.itemDetails && (
                    <IconRemove fill="#d2222d"></IconRemove>
                  )}
                </IonCol>
              </IonRow>
              {/** Details */}
              {listState.itemDetails && (
                <>
                {auctionItemDetailsState.itemnum  &&(
                  <IonRow style={{ borderTop: "1px solid #dedede" }}>
                    <IonCol size="3">
                      <Text fontSize="12px" color="#a2a2a2">
                        Item Number
                      </Text>
                    </IonCol>
                    <IonCol size="9">
                      <Text fontSize="14px" color="#666666">
                        {auctionItemDetailsState.itemnum}
                      </Text>
                    </IonCol>
                  </IonRow>
                )}
                  <IonRow>
                    <IonCol size="3">
                      <Text fontSize="12px" color="#a2a2a2">
                        Quantity
                      </Text>
                    </IonCol>
                    <IonCol size="9">
                      <Text fontSize="14px" color="#666666">
                        {item?.unlimitedquantity && ("Unlimited")}
                        {!item?.unlimitedquantity && (item?.quantityavailable + " Available" )}
                      </Text>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="3">
                      <Text fontSize="12px" color="#a2a2a2">
                        Closing Time
                      </Text>
                    </IonCol>
                    <IonCol size="9">
                      <Text fontSize="14px" color="#666666">
                        <Moment format="MM-DD-YYYY h:mm A">
                          {getLocalDateString(item.closingcst)}
                        </Moment>
                      </Text>
                    </IonCol>
                  </IonRow>

                  {item.location &&(
                    <IonRow>
                      <IonCol size="3">
                        <Text fontSize="12px" color="#a2a2a2">
                          Item Location
                        </Text>
                      </IonCol>
                      <IonCol size="9">
                        <Text fontSize="14px" color="#666666">
                          {item.location}
                        </Text>
                      </IonCol>
                    </IonRow>
                  )}
                  {(auctionItemDetailsState.fairmarketvalue || auctionItemDetailsState.ispriceless) &&
                      <IonRow style={{ borderBottom: "1px solid #dedede" }}>
                        <IonCol size="3">
                          <Text fontSize="12px" color="#a2a2a2">
                            Fair Market Value
                          </Text>
                        </IonCol>
                        <IonCol size="9">
                          <Text fontSize="14px" color="#666666">
                            {(auctionItemDetailsState.ispriceless) && ("Priceless")}
                            {(!auctionItemDetailsState.ispriceless) && (
                                '$' + auctionItemDetailsState.fairmarketvalue
                            )}
                          </Text>
                        </IonCol>
                      </IonRow>
                  }
                  {/** Description */}

                  {(item.description?.length > 0 || item.longdescription?.length > 0) && (
                    <IonRow>
                      <IonCol size="12">
                        <Text fontSize="12px" fontWeight="800" color="#666666">
                          Item Description
                        </Text>
                      </IonCol>
                      <IonCol>
                        <Text fontSize="14px" fontWeight="normal" color="#666666">
                          {getDescription(item)}
                        </Text>
                      </IonCol>
                    </IonRow>
                  )}
                </>
              )}
            </IonGrid>
            <IonRow>
              <IonCol></IonCol>
            </IonRow>

            {/** Bid History Grid */}
            <IonGrid
              style={{ border: "1px solid #dedede", borderRadius: "6px" }}
              onClick={() =>
                toggleListState("bidHistory", !listState.bidHistory)
              }
            >
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <Text fontSize="16px" important fontWeight="800">
                    Bid History
                  </Text>
                </IonCol>
                <IonCol className="ion-align-items-end" size="auto">
                  {!listState.bidHistory && <IconAdd fill="#d2222d"></IconAdd>}
                  {listState.bidHistory && (
                    <IconRemove fill="#d2222d"></IconRemove>
                  )}
                </IonCol>
              </IonRow>
              {listState.bidHistory && (
                <>
                  {bidHistoryData &&
                    bidHistoryData.history &&
                    bidHistoryData.history.map((item: any) => (
                      <IonRow
                        style={{
                          borderTop: "1px solid #dedede",
                          borderBottom: "1px solid #dedede",
                        }}
                      >
                        <IonCol size="9">
                          <IonRow>
                            <Text fontSize="16px">{item.biddername}</Text>
                          </IonRow>
                          <IonRow>
                            <Text fontSize="12px" color="#a2a2a2">
                              {calculateTime(getLocalDateString(item.bidtimecst))} ago
                            </Text>
                          </IonRow>
                        </IonCol>
                        <IonCol
                          size="3"
                          style={{
                            borderRadius: "10px",
                            backgroundColor: "#b6d9ee",
                          }}
                          className="ion-align-self-center"
                        >
                          <Text
                            fontWeight="800"
                            fontSize="16px"
                            textAlign="center"
                          >
                            ${item.amount}
                          </Text>
                        </IonCol>
                      </IonRow>
                    ))}
                </>
              )}
            </IonGrid>
          </>
        )}
      </IonGrid>

      {/*Buy it Now popup */}
      {buyItNow &&
          <>
            {popupBuyItNowCouner < 1 && (
                <>
                  <IonModal
                      isOpen={buyItNow && popupBuyItNowCouner < 1}
                      initialBreakpoint={0.45}
                      // breakpoints={[0.575, 0.5, 0.75]}
                      // backdropDismiss={false}
                      // backdropBreakpoint={0.5}
                      onWillDismiss={(ev) => onWillDismiss(ev)}>
                    <ModalContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="10">
                            <IonRow>
                              <Text style={{ marginTop: "10px", marginLeft: "5px" }} fontSize="18px" fontWeight="800">
                                How many would you like to buy?
                              </Text>
                            </IonRow>
                          </IonCol>
                          <IonCol size="10">
                            <IonRow>
                              <Text style={{ marginLeft: "5px" }} fontSize="14px" fontWeight="800">
                                {auctionItemDetailsState.name}
                              </Text>
                            </IonRow>
                            <IonRow>
                              <Text style={{ marginTop: "5px", marginLeft: "5px" }}  fontSize="14px" fontWeight="300">
                                {item?.unlimitedquantity ? ('Unlimited Quantity')
                                    : (calcQuantityAvailable(auctionItemDetailsState) + ' Available')}
                              </Text>
                            </IonRow>
                          </IonCol>

                          <IonCol size="2">
                            <IonRow className="ion-justify-content-end">
                              <label htmlFor="avaliable"></label>
                              <Select
                                  value={quantitySelected}
                                  onChange={changeQuantitySelected}
                              >
                                {buildQuantityList()}
                              </Select>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonGrid>
                        <IonRow>
                          <IonCol size="10">
                            <Text style={{ marginLeft: "5px" }}  fontSize="12px" color="#a2a2a2">
                              Subtotal({quantitySelected})
                            </Text>
                          </IonCol>
                          <IonCol size="2">
                            <IonRow className="ion-justify-content-end">
                              <Text style={{ marginLeft: "5px" }}  fontSize="14px">${calcaulateTotal()}</Text>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonGrid>
                        <IonRow>
                          <IonCol size="10">
                            <Text style={{ marginLeft: "5px" }} fontSize="14px" color="#a2a2a2" fontWeight="800">
                              Order Total
                            </Text>
                          </IonCol>
                          <IonCol size="2">
                            <IonRow className="ion-justify-content-end">
                              <Text style={{ marginLeft: "5px" }} fontSize="16x" fontWeight="800">
                                ${calcaulateTotal()}
                              </Text>
                            </IonRow>
                          </IonCol>
                        </IonRow>
                      </IonGrid>

                      <IonGrid>
                        <IonRow className="ion-justify-content-center">
                          <IonCol sizeXs={'8'} sizeSm={'8'} sizeMd={'8'} sizeLg={'8'} sizeXl={'8'}>
                            <ButtonItem backgroundColor={"#C21807"}  onClick={clickPopupBuyItNow} height="45px" width={"100%"}>Confirm Purchase</ButtonItem>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </ModalContent>
                  </IonModal>
                </>
            )}
            {popupBuyItNowCouner === 1 && (
                <IonModal isOpen={buyItNow && popupBuyItNowCouner === 1}
                          initialBreakpoint={0.45}
                          onWillDismiss={(ev) => onWillDismiss(ev)}>
                  <ModalContent>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <Text important fontSize="24px" fontWeight="800">
                        Thanks for your purchase
                      </Text>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      <Text fontSize="20px">
                        This item has now been added to your cart. You can pay for it
                        now, or pay for it later.
                      </Text>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol size="6" onClick={continueBidding}>
                      <ButtonItem
                          height="45px"
                          backgroundColor="#ffffff"
                          color="#4c4c4c"
                          width={"100%"}
                      >
                        Continue Bidding
                      </ButtonItem>
                    </IonCol>
                    <IonCol size="6" onClick={goToCheckout}>
                      <ButtonItem height="45px" width={"100%"}>Go to Cart</ButtonItem>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                  </ModalContent>
                </IonModal>
            )}
          </>
      }
      <div style={{width: '75%', margin: 'auto', borderTop: "1px solid #666", marginTop: '50px'}}>
        <div style={{verticalAlign: 'middle', color: '#666', zIndex: 1, marginTop: '5px', marginBottom: "0px", backgroundColor: '#f2f2f2', position: "relative", padding:"0 0 0 0", textAlign: "center", fontSize: 12}}>
          AuctionSnap™. All Rights Reserved by DonorSnap.{" "}<br />
          <span style={{ color: "red" }}>
                    <a><Link style={{ textDecoration: 'none' }} to={"/" + eventState.eventcode + "/privacy-policy"}>Privacy Policy</Link></a>
          </span>
        </div>
      </div>
      </>
  );
};

export default ItemDetailsPage;
