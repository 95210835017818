import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { client } from "../../util/api/fetchData"

type AuctionItemDetailsState = {
  message: string
  tags: any
  status: "idle" | "loading" | "succeeded" | "failed"
  error: any
  itemid: string
  historyid: number
  name: string
  description: string
  longdescription: string
  itemnum: string
  donatedby: string
  fairmarketvalue: any
  ispriceless: any
  quantityavailable: any
  maxpurchasequantity: any
  buyitnowonly: any
  buyitnowavailable: any
  buyitnowprice: any
  currentbid: any
  minimumbidincrease: any
  bidstatus: any
  location: string
  unlimitedquantity: any
  accountisautobid: any
  accountautobidamount: any
  accountautobidincrement: any
  needscardtobid: any
}

const initialState: AuctionItemDetailsState = {
  message: "",
  tags: [],
  status: "idle",
  error: null,
  itemid: "",
  historyid: null,
  name: "",
  description: "",
  longdescription: "",
  itemnum: "",
  donatedby: "",
  fairmarketvalue: 0,
  ispriceless: false,
  quantityavailable: "",
  maxpurchasequantity: 0,
  buyitnowonly: false,
  buyitnowavailable: false,
  buyitnowprice: "",
  currentbid: "",
  minimumbidincrease: "",
  bidstatus: "",
  location: "",
  unlimitedquantity: false,
  accountisautobid: false,
  accountautobidamount: 0,
  accountautobidincrement: 0,
  needscardtobid: false
}

function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

export const fetchAuctionItemDetailsData = createAsyncThunk(
  "auctionitemsdetail/fetchAuctionItemDetailsData",
  async (config: any) => {
    const token = localStorage.getItem("token")
    const response = await client.post(
      config.url,
      {
        itemid: parseInt(config.itemId)
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    const parsedData = JSON.parse(response.jsonString)
    return parsedData
  }
)

export const slice = createSlice({
  name: "auctionItemDetails",
  initialState,
  reducers: {
    resetItemDetailsState: (state) => {
        return {
          ...initialState
        }
    },
    updateItemDetailsOnBid: (state, action) => {
      if (state.itemid && state.itemid === action.payload.itemid) {
        return {
          ...state,
          currentbid: action.payload.bidamount,
          quantityavailable: action.payload.quantity
        }
      } else {
        return state
      }
    },
    updateItemDetailsHistoryId: (state, action) => {
      if (state.itemid && state.itemid === action.payload.itemid && action.payload.historyid) {
        return {
          ...state,
          historyid: action.payload.historyid
        }
      } else {
        return state
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      "auctionitemsdetail/fetchAuctionItemDetailsData/pending",
      (state, action) => {
        state.status = "loading"
      }
    )
    builder.addCase(
      "auctionitemsdetail/fetchAuctionItemDetailsData/fulfilled",
      (state, action: any) => {
        Object.keys(state).forEach((key) => {
          if (key in action.payload) {
            if (hasKey(state, key)) {
              let value = action.payload[key]
              if (key !== 'historyid') {
                state[key] = value
              }
              // state["image"] = await fetch('data:iamge/jpeg;base64, ${value}')
            }

            return
          }
        })
        state.status = "succeeded"
      }
    )
    builder.addCase(
      "auctionitemsdetail/fetchAuctionItemDetailsData/rejected",
      (state, action: any) => {
        state.status = "failed"
        state.error = action.error.message
      }
    )
  }
})

export const { updateItemDetailsOnBid } = slice.actions
export const { updateItemDetailsHistoryId, resetItemDetailsState } = slice.actions

export default slice.reducer
