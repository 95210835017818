import React from "react"
import { useSelector } from "react-redux"
import {Route, Routes} from "react-router-dom"
import AccountProfileHeader from "./AccountProfileHeader"
import AuctionItemsHeader from "./AuctionItemHeader"
import EventWelcome from "./EventWelcome"
import Img from "./Img"
import LoginWelcome from "./LoginWelcome"
import LogoContainer from "./LogoContainer"
import LogoWrapper from "./LogoWrapper"
import SecondaryHeader from "./SecondaryHeader"
import Wrapper from "./Wrapper"
import logo from "./AuctionSnap.png"
import NotFound from "../../pages/NotFound/NotFound";

const Header: React.FC = (props) => {
  const eventResponse = useSelector((state: any) => state.event.eventData)
  const itemid = useSelector((state: any) => state.auctionItemDetails.itemid);
  const eventFetchState = useSelector(
    (state: any) => state.event.status === "loading"
  )

  const user = useSelector((state: any) => state.user)

  return (
    <Wrapper>
        <Routes>
            <Route path="/:eventcode/slideshow/">
              {/*<LoginWelcome*/}
              {/*  eventResponse={eventResponse}*/}
              {/*  isEventLoading={eventFetchState}*/}
              {/*></LoginWelcome>*/}
            </Route>
            <Route path="/:eventcode"
              element={
                    <LoginWelcome
                        eventResponse={eventResponse}
                        isEventLoading={eventFetchState}
                    />
                }
            >
            </Route>
            <Route  path="/:eventcode/auth/resetPassword/:id"
                element={
                    <LoginWelcome
                        eventResponse={eventResponse}
                        isEventLoading={eventFetchState}
                    />
                }
            >
            </Route>
            <Route path="/:eventcode/verify-email"
               element={
                   <LoginWelcome
                       eventResponse={eventResponse}
                       isEventLoading={eventFetchState}
                   />
               }
            >
            </Route>
            <Route path="/:eventcode/verify"
               element={
                   <LoginWelcome
                       eventResponse={eventResponse}
                       isEventLoading={eventFetchState}
                   />
               }
            >
            </Route>
            <Route path="/:eventcode/login"
                element={
                    <LoginWelcome
                        eventResponse={eventResponse}
                        isEventLoading={eventFetchState}
                    />
                }
            >
            </Route>
            <Route path="/:eventcode/create-account"
               element={
                   <LoginWelcome
                       eventResponse={eventResponse}
                       isEventLoading={eventFetchState}
                   />
               }
            >
            </Route>
            <Route path="/:eventcode/setup-account"
               element={
                   <LoginWelcome
                       eventResponse={eventResponse}
                       isEventLoading={eventFetchState}
                   />
               }
            >
            </Route>
            <Route path="/:eventcode/reset-password"
                   element={
                       <LoginWelcome
                           eventResponse={eventResponse}
                           isEventLoading={eventFetchState}
                       />
                   }
            >
            </Route>
            <Route path="/:eventcode/reset-password-email"
              element={<EventWelcome
                eventResponse={eventResponse}
                isEventLoading={eventFetchState}
                showWelcomeMsg={true}
                hideHamburgerMenu={false}
              ></EventWelcome>}
            >
            </Route>
            <Route path="/:eventcode/welcome/"
               element={
                   <AuctionItemsHeader
                       eventResponse={eventResponse}
                   ></AuctionItemsHeader>
               }
            >
            </Route>
            <Route path="/:eventcode/auction-items"
               element={
                   <AuctionItemsHeader
                       eventResponse={eventResponse}
                   ></AuctionItemsHeader>
               }
            />
            <Route path="/:eventcode/auction-items/:id"
                   element={
                       <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items'} itemid={itemid}></AuctionItemsHeader>
                   }
            />
            <Route path="/:eventcode/reset-password-account"
                   element={
                       <AccountProfileHeader
                           eventResponse={eventResponse}
                           isEventLoading={eventFetchState}
                           showWelcomeMsg={true}
                           userInfo={user}
                           title="Reset Password"
                           path="/:eventcode/my-account"
                       >
                       </AccountProfileHeader>
                   }
            />
            <Route path="/:eventcode/account-profile"
                   element={
                       <AccountProfileHeader
                           eventResponse={eventResponse}
                           isEventLoading={eventFetchState}
                           showWelcomeMsg={false}
                           userInfo={user}
                           title="My Profile"
                           path={"/" + eventResponse.eventcode + "/my-account"}
                       >
                       </AccountProfileHeader>
                   }
            />
            <Route path="/:eventcode/contact-us"
                   element={
                       <AccountProfileHeader
                           eventResponse={eventResponse}
                           isEventLoading={eventFetchState}
                           showWelcomeMsg={false}
                           userInfo={user}
                           title="Contact Us"
                           path={"/" + eventResponse.eventcode + "/my-account"}
                       >
                       </AccountProfileHeader>
                   }
            />
            <Route path="/:eventcode/account-notifications"
              element={
                  <AccountProfileHeader
                      eventResponse={eventResponse}
                      isEventLoading={eventFetchState}
                      showWelcomeMsg={false}
                      userInfo={user}
                      title="Notifications 1"
                  >
                  </AccountProfileHeader>
              }
            />
            <Route path="/:eventcode/account-update-payment-method/login"
                   element={
                        <></>
                   }
            />
            <Route path="/:eventcode/account-update-payment-method"
                   element={
                       <AccountProfileHeader
                           eventResponse={eventResponse}
                           isEventLoading={eventFetchState}
                           showWelcomeMsg={false}
                           userInfo={user}
                           title="Update Payment Method"
                       >
                       </AccountProfileHeader>
                   }
            />
            <Route path="/:eventcode/account-update-payment-method/auction-items/:itemid?"
                   element={
                       <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items/' + itemid} itemid={itemid}></AuctionItemsHeader>
                   }
            />
            <Route path="/:eventcode/account-update-payment-method/:id"
                   element={
                       <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items/' + itemid} itemid={itemid}></AuctionItemsHeader>
                   }
            />
            <Route path="/:eventcode/place-bid/:id"
                   element={
                       <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items/' + itemid} itemid={itemid}></AuctionItemsHeader>
                   }
            />
            <Route path="/:eventcode/auto-bid/:id"
                   element={
                       <AuctionItemsHeader eventResponse={eventResponse} includeBack={true} backPath={'/' + eventResponse.eventcode + '/auction-items/' + itemid} itemid={itemid}></AuctionItemsHeader>
                   }
            />
            <Route path="/:eventcode/not-found"
                   element={
                       <LogoWrapper>
                           <LogoContainer>
                               <Img alt="auction-snap" src={logo} margin="5px" />
                           </LogoContainer>
                       </LogoWrapper>
                   }
            />
            <Route path="/:eventcode/my-orders"
               element={
                   <AuctionItemsHeader
                       eventResponse={eventResponse}
                   ></AuctionItemsHeader>
               }
            />
            <Route path="/:eventcode/my-account"
                   element={
                       <AuctionItemsHeader
                           eventResponse={eventResponse}
                       ></AuctionItemsHeader>
                   }
            />
            <Route path="/:eventcode/cart"
              element={
                  <AuctionItemsHeader
                      eventResponse={eventResponse}
                  ></AuctionItemsHeader>
              }
            />
            <Route path="/event/open-bids"
              element={
                  <SecondaryHeader
                      eventResponse={eventResponse}
                      isEventLoading={eventFetchState}
                      showWelcomeMsg={false}
                      userInfo={user}
                  ></SecondaryHeader>
              }
            />
            <Route path="/:eventcode/checkout"
               element={
                   <AuctionItemsHeader
                       eventResponse={eventResponse}
                   ></AuctionItemsHeader>
               }
            />
            <Route path="/:eventcode/checkout-item/:historyid"
                   element={
                       <AuctionItemsHeader
                           eventResponse={eventResponse}
                       ></AuctionItemsHeader>
                   }
            />
            <Route path="/"
                element={
                    <NotFound />
                }
            />
            <Route path={"/*"}
                   element={<></>}
            />
        </Routes>
    </Wrapper>
  )
}

export default Header
