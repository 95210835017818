import { useSelector } from "react-redux"

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { client } from "../../util/api/fetchData"
import { AppState } from "../store"

type UserState = {
  userName: string | null
  firstname: string | null
  lastname: string | null
  email: string | null
  phoneNumber: number | null
  addressInfo: {
    lineOne: string | null
    lineTwo: string | null
    city: string | null
    state: string | null
    postalCode: number | null
  }
  error: any
  redirect: boolean
  isLoggedIn: boolean
  accountstatus: string
  accountSetupComplete: boolean
  showupdatepaymentmethod: boolean
  needscardtoregister: boolean
  authorizebidder: boolean
  bidderdisplaynumber: string
  bidanonymously: boolean
  allowtexting: boolean
  userid: any
  eventcode: string | null,
  notifications: [],
}

export const initialState: UserState | any = {
  firstname: null,
  lastname: null,
  userName: null,
  email: "",
  phoneNumber: null,
  addressInfo: {
    lineOne: null,
    lineTwo: null,
    city: null,
    state: null,
    postalCode: null
  },
  error: null,
  redirect: false,
  isLoggedIn: false,
  accountstatus: "",
  showupdatepaymentmethod: false,
  needscardtoregister: false,
  accountSetupComplete: false,
  authorizebidder: true,
  bidderdisplaynumber: "",
  bidanonymously: false,
  allowtexting: false,
  userid: null,
  eventcode: null,
  auctioncode: null,
  notifications: null,
}

export const verifyEmail = createAsyncThunk(
  "user/verifyEmail",
  async (config: any) => {
    const response = await client.post(config.url, {
      email: config.email,
      eventcode: config.eventcode,
      auctioncode: config.auctioncode
    })
    return response
  }
)

export const sendUserInfo = createAsyncThunk(
  "user/sendUserInfo",
  async (config: any) => {
    const response = await client.post(config.url, {
        email: config.email,
        password: config.password,
        eventcode: config.eventcode,
        auctioncode: config.auctioncode
    })
    return response
  }
)

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (config: any) => {
    const url = config.url
    const token = localStorage.getItem("token")
    delete config.url
    const response = await client.put(
      url,
      {
        ...config
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    return response
  }
)

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (config: any) => {
    const token = localStorage.getItem("token")
    const response = await client.post(
      config.url,
      {
          password: config.password,
          auctioncode: config.auctioncode
      },
      {
        headers: {
          Authorization: "Bearer " + token
        }
      }
    )
    return response
  }
)

export const resetPasswordLink = createAsyncThunk(
    "user/resetPassword",
    async (config: any) => {
      const token = localStorage.getItem("token")
      const response = await client.post(
          config.url,
          {
            password: config.password,
            token: config.id
          },
          {
            headers: {
              Authorization: "Bearer " + token
            }
          }
      )
      return response
    }
)

export const forgotPassword = createAsyncThunk(
  "user/resetPassword",
  async (config: any) => {
    const response = await client.post(config.url, {
      email: config.email,
      eventcode: config.eventcode,
      auctioncode: config.auctioncode
    })
    return response
  }
)

export const checkTokenOnRefresh = createAsyncThunk(
  "user/refreshBrowser",
  async (config: any) => {
    const token = "Bearer " + localStorage.getItem("token")
    const url = config.url
    delete config.url

    if (token) {
      const response = await client.get(url, {
        headers: {
          Authorization: token
        }
      })

      return response
    } else {
      return Promise.reject("Something wrong with the token or connection")
    }
  }
)

export const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserInfo: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        // tslint:disable-next-line
        // need typescript working for this
        if (key in state) {
          state[key] = action.payload[key]
        }
      })
    },
    updateStateOnLogout: (state) => {
      return {
        ...initialState,
        email: state.email,
        accountstatus: state.accountstatus,
        accountSetupComplete: state.accountSetupComplete
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase("user/refreshBrowser/pending", (state, action) => {})
    builder.addCase("user/refreshBrowser/fulfilled", (state, action: any) => {
      const user = JSON.parse(action.payload.jsonString)
      state.isLoggedIn = true
      state.lastname = user.lastname
      state.accountSetupComplete = user.setupcomplete
      state.bidanonymously = user.bidanonymously
      state.phoneNumber = user.cellphone
      state.allowtexting = user.allowtexting
      state.addressInfo.lineOne = user.address
      state.addressInfo.lineTwo = user.address2
      state.addressInfo.city = user.city
      state.addressInfo.state = user.state
      state.addressInfo.postalCode = user.zip
      state.eventcode = user.eventcode
      state.notifications = user.notifications

      Object.keys(user).forEach((key) => {
        // tslint:disable-next-line
        // need typescript working for this
        if (key in state) {
          state[key] = user[key]
        }
      })
    })
    builder.addCase("user/refreshBrowser/rejected", (state, action: any) => {
      state.isLoggedIn = false
    })
    builder.addCase("user/sendUserInfo/pending", (state, action) => {})
    builder.addCase("user/sendUserInfo/fulfilled", (state, action: any) => {
      state.isLoggedIn = true
    })
    builder.addCase("user/sendUserInfo/rejected", (state, action: any) => {
      console.log(action.error.message)
    })
    builder.addCase("user/verifyEmail/pending", (state, action) => {})
    builder.addCase("user/verifyEmail/fulfilled", (state, action: any) => {})
    builder.addCase("user/verifyEmail/rejected", (state, action: any) => {
      state.redirect = true
    })
    builder.addCase("user/updateUserProfile/pending", (state, action) => {})
    builder.addCase(
      "user/updateUserProfile/fulfilled",
      (state, action: any) => {
        const user = JSON.parse(action.payload.jsonString)
        state.isLoggedIn = true
        state.lastname = user.lastname
        state.accountSetupComplete = user.setupcomplete
        state.bidanonymously = user.bidanonymously
        state.authorizebidder = user.authorizebidder
        state.phoneNumber = user.cellphone
        state.allowtexting = user.allowtexting
        state.addressInfo.lineOne = user.address
        state.addressInfo.lineTwo = user.address2
        state.addressInfo.city = user.city
        state.addressInfo.state = user.state
        state.addressInfo.postalCode = user.zip
        state.eventcode = user.eventcode
        state.notifications = user.notifications

        Object.keys(user).forEach((key) => {
          // tslint:disable-next-line
          // need typescript working for this
          if (key in state) {
            state[key] = user[key]
          }
        })
      }
    )
    builder.addCase(
      "user/updateUserProfile/rejected",
      (state, action: any) => {}
    )
  }
})

export const useUserInfo = () => useSelector((state: AppState) => state.user as UserState)

export const { updateUserInfo, updateStateOnLogout } = slice.actions

export default slice.reducer

